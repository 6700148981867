import React from "react";
import Banner from "../../component/homepage/Banner";
import HomeBooking from "../../component/homepage/HomeBooking";
import About from "../../component/about/About";
import Info from "../../component/homepage/Info";
import Services from "../../component/homepage/Services";
// import GoogleReviews from "../../component/reviews/GoogleReviews";
import ElfsightWidget from "../../component/ElfsightWidget";

export default function Home(){
    // const placeId = "ChIJF8uHjwHEGToRnG4XTZdrTVo"; // Example place ID
return(
    <>
    <Banner />
    <HomeBooking />
    <About />
    <Info/>
    <Services/>
    {/* <GoogleReviews placeId={placeId} /> */}
    <ElfsightWidget />
    </>
)
}