import React from "react";

import About from "../../component/about/About";
import aboutImgOne from "../../assets/img/about-1.jpg"
import aboutImgTwo from "../../assets/img/about-2.jpg"
import aboutImgThree from "../../assets/img/about-3.jpg"
import aboutImgFour from "../../assets/img/about-4.jpg"
export default function Abouts() {
    return (
        <>
        <About />
        </>
        // <>
        //     <div className="container-xxl py-5">
        //         <div className="container">
        //             <div className="row g-5 align-items-center">
        //                 <div className="col-lg-6">
        //                     <h6 className="section-title text-start text-primary text-uppercase">
        //                         About Us
        //                     </h6>
        //                     <h1 className="mb-4">
        //                         Welcome to{" "}
        //                         <span className="text-primary text-uppercase">Odyssey Group Of Hotels</span>
        //                     </h1>
        //                     <p className="mb-4">
        //                         The friendly greeting you get when you visit our Hotels. It’s how we do business, and always have. Sure, we’ve grown, but our aim is still to be like a hometown neighbor-reliable, valued, trustworthy.
        //                     </p>
        //                     <div className="row g-3 pb-4">
        //                         <div className="col-sm-4 wow fadeIn" data-wow-delay="0.1s">
        //                             <div className="border rounded p-1">
        //                                 <div className="border rounded text-center p-4">
        //                                     <i className="fa fa-hotel fa-2x text-primary mb-2" />
        //                                     <h2 className="mb-1" data-toggle="counter-up">
        //                                         1234
        //                                     </h2>
        //                                     <p className="mb-0">Rooms</p>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                         <div className="col-sm-4 wow fadeIn" data-wow-delay="0.3s">
        //                             <div className="border rounded p-1">
        //                                 <div className="border rounded text-center p-4">
        //                                     <i className="fa fa-users-cog fa-2x text-primary mb-2" />
        //                                     <h2 className="mb-1" data-toggle="counter-up">
        //                                         1234
        //                                     </h2>
        //                                     <p className="mb-0">Staffs</p>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                         <div className="col-sm-4 wow fadeIn" data-wow-delay="0.5s">
        //                             <div className="border rounded p-1">
        //                                 <div className="border rounded text-center p-4">
        //                                     <i className="fa fa-users fa-2x text-primary mb-2" />
        //                                     <h2 className="mb-1" data-toggle="counter-up">
        //                                         1234
        //                                     </h2>
        //                                     <p className="mb-0">Clients</p>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <a className="btn btn-primary py-3 px-5 mt-2" href="">
        //                         Explore More
        //                     </a>
        //                 </div>
        //                 <div className="col-lg-6">
        //                     <div className="row g-3">
        //                         <div className="col-6 text-end">
        //                             <img
        //                                 className="img-fluid rounded w-75 wow zoomIn"
        //                                 data-wow-delay="0.1s"
        //                                 src={aboutImgOne}
        //                                 style={{ marginTop: "25%" }}
        //                             />
        //                         </div>
        //                         <div className="col-6 text-start">
        //                             <img
        //                                 className="img-fluid rounded w-100 wow zoomIn"
        //                                 data-wow-delay="0.3s"
        //                                 src={aboutImgTwo}
        //                             />
        //                         </div>
        //                         <div className="col-6 text-end">
        //                             <img
        //                                 className="img-fluid rounded w-50 wow zoomIn"
        //                                 data-wow-delay="0.5s"
        //                                 src={aboutImgThree}
        //                             />
        //                         </div>
        //                         <div className="col-6 text-start">
        //                             <img
        //                                 className="img-fluid rounded w-75 wow zoomIn"
        //                                 data-wow-delay="0.7s"
        //                                 src={aboutImgFour}
        //                             />
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </>
    )
}