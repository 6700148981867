
import { useState, useEffect } from "react";
import roomOne from "../../assets/img/room-1.jpg"
import headerImg from "../../assets/img/carousel-1.jpg";
import { fetchAllHotel } from "../../crud/hotel.crud";
export default function Hotel() {
  
  const [hotel, setHotel] = useState([]);
  
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchAllHotel().then((res) => {
      
      let htgs = res.data;
      setHotel(htgs);
      setIsLoading(false);
    });
  }, []);


  // if (transition.isRunning || isLoading) {
  //     return <Loader />;
  // }
  return (
    <>
      {/* header start */}
      <div
        className="container-fluid page-header mb-5 p-0"
        style={{ backgroundImage: `url(${`${headerImg}`})` }}
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">Our Hotels</h1>
            
          </div>
        </div>
      </div>

      {/* header end */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title text-center text-primary text-uppercase">
               Hotels
            </h6>
            <h1 className="mb-5">
              Explore Our <span className="text-primary text-uppercase">Hotels</span>
            </h1>
          </div>
          <div className="row d-flex justify-content-center g-4">
            {hotel.map((item, index) => (
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s" key={index}>
                <div className="room-item shadow rounded overflow-hidden">
                  <div className="position-relative">
                    <img className="img-fluid" src={roomOne} alt="" />
                  </div>
                  <div className="p-4 mt-2">
                    <div className="d-flex justify-content-between mb-3">
                      <h5 className="mb-0">{item.hotelName}</h5>
                      <div className="ps-2">
                        <small className="fa fa-star text-primary" />
                        <small className="fa fa-star text-primary" />
                        <small className="fa fa-star text-primary" />
                        <small className="fa fa-star text-primary" />
                        <small className="fa fa-star text-primary" />
                      </div>
                    </div>
                    <div className="d-flex mb-3">
                      <small className=" me-3 pe-3">
                        {item.hotelCity}
                      </small>
                    </div>
                    <p className="text-body mb-3">{item.hotelAddress}</p>
                    <div className="d-flex justify-content-between">
                      <a className="btn btn-sm btn-primary rounded py-2 px-4" href={`/room/${item.hotelSlug}`}>
                        View Rooms
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>

    </>
  )
}