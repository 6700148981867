import React, { useEffect } from 'react';

const ElfsightWidget = () => {
  useEffect(() => {
    // Check if the script is already present
    if (!document.getElementById('elfsight-platform-script')) {
      // Dynamically create and append the Elfsight script
      const script = document.createElement('script');
      script.src = 'https://static.elfsight.com/platform/platform.js';
      script.async = true;
      script.id = 'elfsight-platform-script'; // Add an ID to avoid reloading the script
      document.body.appendChild(script);
    }
  }, []);

  return (
    <div>
      {/* Elfsight widget div */}
      <div
        className="elfsight-app-5a848654-e674-4326-97ad-2288c3833311"
        data-elfsight-app-lazy
      ></div>
    </div>
  );
};

export default ElfsightWidget;
