
import React, { useEffect, useState } from "react";


export default function Headers() {
  const [isSticky, setIsSticky] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const handleScroll = () => {
    const scroll = window.scrollY || document.documentElement.scrollTop;
    setIsSticky(scroll >= 10);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const openSearch = () => {
    setSearchOpen(true);
  };

  const closeSearch = () => {
    setSearchOpen(false);
  };
  useEffect(() => {
    const handleWindowClick = (event) => {
      // if (
      //   searchCardRef.current &&
      //   !searchCardRef.current.contains(event.target)
      // ) {
      //   setSearchOpen(false);
      // }

      if (!event.target.matches(".user-dropdown-icon i")) {
        // Handle other logic if needed
      }
    };

    window.addEventListener("click", handleWindowClick);

    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, []);
  const [isHamburgerActive, setIsHamburgerActive] = useState(false);
  const [isSlidenavActive, setIsSlidenavActive] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 100; // Change this value based on your design
      setIsScrolled(scrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleMouseEnter = () => {
    if (window.matchMedia("(min-width: 992px)").matches) {
      setIsDropdownOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (window.matchMedia("(min-width: 992px)").matches) {
      setIsDropdownOpen(false);
    }
  };
  return (
    <>
      {/* ===============  header area start =============== */}
      <div className="container-fluid bg-dark px-0">
        <div className="row gx-0">
          <div className="col-lg-3 bg-dark d-none d-lg-block">
            <a
              href="/"
              className="navbar-brand w-100 h-100 m-0 p-0 d-flex align-items-center justify-content-center"
            >
              <h1 className="header-title">
                Odyssey<span className="sub-title">Group of hotels</span>
              </h1>
            </a>
          </div>
          <div className="col-lg-9">
            <div className="row gx-0  d-none d-lg-flex justify-content-end">
              <div className="col-lg-5 px-5 text-end">
                <div className="d-inline-flex align-items-center py-2">
                  <a class="me-3 " href="https://www.facebook.com/OdysseyGroupofHotels/"><i class="fab fa-facebook-f"></i></a>
                  <a class="me-3 " href=""><i class="fab fa-twitter"></i></a>
                  <a class="me-3 " href=""><i class="fab fa-linkedin-in"></i></a>
                  <a class="me-3 " href=""><i class="fab fa-instagram"></i></a>
                  <a class="" href=""><i class="fab fa-youtube"></i></a>
                </div>
              </div>
            </div>
            <nav className="navbar navbar-expand-lg bg-dark navbar-dark p-3 p-lg-0">
              <a href="index.html" className="navbar-brand d-block d-lg-none">
              <h1 className="header-title">
                Odyssey<span className="sub-title">Group of hotels</span>
              </h1>
              </a>
              <button
                type="button"
                className="navbar-toggler"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className="collapse navbar-collapse justify-content-center"
                id="navbarCollapse"
              >
                <div className="navbar-nav mr-auto py-0">
                  <a href="/" className="nav-item nav-link active">
                    Home
                  </a>
                  <a href="/about" className="nav-item nav-link">
                    About
                  </a>
                  <div className="nav-item dropdown" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <a href="#" className="nav-link dropdown-toggle" onClick={handleDropdownToggle}>
                      Our Hotels
                    </a>
                    <div className={`dropdown-menu rounded-0 m-0 ${isDropdownOpen ? "show" : ""}`}>
                      <a href="/room/the-odyssey-inn" className="dropdown-item">The odyssey inn</a>
                      <a href="/room/the-greenstar-inn" className="dropdown-item">The Greenstar Inn</a>
                    </div>
                  </div>
                  <a href="/contact" className="nav-item nav-link">
                    Contact
                  </a>
                </div>
                <a className="nav-item nav-link "><p className=" mb-0 text-white"><i className="fa fa-envelope text-white me-2" />info@odysseyhotels.in</p></a>
                <a className="nav-item nav-link" href="tel:+919777713137"><p className=" mb-0 text-white"><i className="fa fa-phone-alt text-white me-2" />+91 97777 13137 </p></a>
                {/* <a
                  href=""
                  className="btn btn-primary rounded-0 py-4 px-md-5 d-none d-lg-block"
                >
                  Book Now
                  <i className="fa fa-arrow-right ms-3" />
                </a> */}
              </div>
            </nav>
          </div>
        </div>
      </div>


      {/* ===============  header area end =============== */}
    </>
  );
}
