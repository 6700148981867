
import { useState, useEffect, useTransition } from "react";
import { Link, useLocation } from "react-router-dom";
import { fetchSingleHotel } from './../../../crud/hotel.crud';
import roomOne from "../../../assets/img/room-1.jpg"
import headerImg from "../../../assets/img/carousel-1.jpg";
import { mapImagesToRooms } from "../../../utils/mapImagesToRooms";
import RoomImages from "../../RoomImages";
export default function Rooms() {
  const transition = useTransition();
  const location = useLocation();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");
  const slugHotel =
    pathSegments.length > 0 ? pathSegments[pathSegments.length - 1] : null;


  const [hotel, setHotel] = useState([]);
  const [hotelID, setHotelID] = useState(null);
  const [hotelRooms, setHotelRooms] = useState([]);
  const [disHotelRooms, setdisHotelRooms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchSingleHotel(slugHotel).then((res) => {
      
      let htgs = res.data;
      setHotel(htgs);
      setHotelID(htgs[0]?._id);
      const roomsWithImages = mapImagesToRooms(htgs[0].hotelRooms, slugHotel);
      setHotelRooms(roomsWithImages);
      setIsLoading(false);
    });
  }, [slugHotel]);

  useEffect(() => {
    const distinctCategories = [...new Set(hotelRooms.map(room => room.roomCategory))];
    const categoriesData = distinctCategories.map(category => {
      const categoryRooms = hotelRooms.filter(room => room.roomCategory === category);
      const roomCapacity = [...new Set(categoryRooms.map(room => room.roomCapacity))];
      const roomPrice = [...new Set(categoryRooms.map(room => room.roomPrice))];
      const categoryCount = categoryRooms.length;
      const totalPrice = categoryRooms.reduce((acc, room) => acc + room.roomPrice, 0);
      const roomNumbers = categoryRooms.map(room => room.roomNumber);
      const images = categoryRooms[0]?.images || [];
      return { category, categoryCount, totalPrice, roomNumbers, roomCapacity, roomPrice, images  };
    });
    setdisHotelRooms(categoriesData)
  }, [hotelRooms])

  // if (transition.isRunning || isLoading) {
  //     return <Loader />;
  // }
  return (
    <>
      {/* header start */}
      <div
        className="container-fluid page-header mb-5 p-0"
        style={{ backgroundImage: `url(${`${headerImg}`})` }}
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">{hotel[0]?.hotelName}</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#">Pages</a>
                </li>
                <li className="breadcrumb-item text-white active" aria-current="page">
                  Rooms
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      {/* header end */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title text-center text-primary text-uppercase">
              Our Rooms
            </h6>
            <h1 className="mb-5">
              Explore Our <span className="text-primary text-uppercase">Rooms</span>
            </h1>
          </div>
          <div className="row g-4">
            {disHotelRooms.map((item, index) => (
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s" key={index}>
                <div className="room-item shadow rounded overflow-hidden">
                  <div className="position-relative">
                    {/* <img className="img-fluid" src={roomOne} alt="" /> */}
                    <RoomImages images={item.images} />
                    <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4" style={{zIndex:"100"}}>
                      INR{" "}{item.roomPrice}/Night
                    </small>
                  </div>
                  <div className="p-4 mt-2">
                    <div className="d-flex justify-content-between mb-3">
                      <h5 className="mb-0">{item.category}</h5>
                      <div className="ps-2">
                        <small className="fa fa-star text-primary" />
                        <small className="fa fa-star text-primary" />
                        <small className="fa fa-star text-primary" />
                        <small className="fa fa-star text-primary" />
                        <small className="fa fa-star text-primary" />
                      </div>
                    </div>
                    <div className="d-flex mb-3">
                      <small className="border-end me-3 pe-3">
                        <i className="fa fa-bed text-primary me-2" />{item.roomCapacity} Bed
                      </small>
                      <small className="border-end me-3 pe-3">
                        <i className="fa fa-bath text-primary me-2" />1 Bath
                      </small>
                      <small>
                        <i className="fa fa-wifi text-primary me-2" />
                        Wifi
                      </small>
                    </div>
                    <p className="text-body mb-3">Step into our elegantly designed rooms, each offering a blend of modern comforts and classic charm.</p>
                    <div className="d-flex justify-content-between">
                      <a className="btn btn-sm btn-primary rounded py-2 px-4" href="">
                        View Detail
                      </a>
                      <a className="btn btn-sm btn-dark rounded py-2 px-4" href={`/booking/${slugHotel}/${item.category.toLowerCase().replace(/\s+/g, '-')}`}>
                        Book Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

    </>
  )
}