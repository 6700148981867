import React, { useState, useEffect } from 'react';
import RoomCard from './RoomCard';

const Step2 = ({ onNext, onBack, allRoomsAvab, initialData }) => {
    const [selectedRooms, setSelectedRooms] = useState([]);
    const [roomCategoriesData, setRoomCategoriesData] = useState([]);

    // Extract pax from initialData
    const { pax } = initialData;
    const { adultCount, childCount } = pax;

    useEffect(() => {
        const distinctCategories = [...new Set(allRoomsAvab.map(room => room.roomCategory))];
        const categoriesData = distinctCategories.map(category => {
            const categoryRooms = allRoomsAvab.filter(room => room.roomCategory === category);
            const roomPrice = [...new Set(categoryRooms.map(room => room.roomPrice))];
            const roomCapacity = [...new Set(categoryRooms.map(room => room.roomCapacity))];
            const categoryCount = categoryRooms.length;
            const roomNumbers = categoryRooms.map(room => room.roomNumber);
            return { category, categoryCount, roomNumbers, roomPrice, roomCapacity };
        });
        setRoomCategoriesData(categoriesData);
    }, [allRoomsAvab]);

    const selectRoom = (roomDetails) => {
        
        setSelectedRooms([...selectedRooms, roomDetails]);
    };

    const handleNext = () => {
        onNext({ selectedRooms });
    };

    return (
        <div className="container my-5">
            <h3 className="my-4 text-primary text-center">Step 2: Select Room</h3>
            <div className="room-selection wow fadeInUp" data-wow-delay="0.2s">
                {roomCategoriesData.map((categoryData, roomIndex) => (
                    <RoomCard 
                        key={roomIndex}
                        roomName={categoryData.category} 
                        availableNumber={categoryData.categoryCount} 
                        price={categoryData.roomPrice} 
                        roomNumbers={categoryData.roomNumbers} 
                        roomCapacity={categoryData.roomCapacity} 
                        adultCount={adultCount} // Passing adultCount
                        childCount={childCount} // Passing childCount
                        onSelect={selectRoom} 
                    />
                ))}
                <div className="d-flex justify-content-between mt-3">
                    <div onClick={onBack} className="back-button" style={{ cursor: "pointer" }}>
                        <i className="bx bx-left-arrow-alt"></i> Back
                    </div>
                    <button className="btn btn-primary" onClick={handleNext} disabled={selectedRooms.length === 0}>
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Step2;
