import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./component/layout";
import Home from "./pages/home";
import Booking from './pages/booking';
import Contact from "./pages/contact";
import PaymentSuccess from "./pages/paymentSuccess";
import Hotels from "./pages/hotels";
import Abouts from "./pages/about";
import Room from "./pages/rooms";



const router = createBrowserRouter([
  
  {
    path: "/",
    Component: Layout,
    children: [
      {
        index: true,
        element: <Home/>
      },
      {
        path: "/about",
        element: <Abouts />
      },
      {
        path: "/hotels",
        element: <Hotels/>
      },
      {
        path: "/room/:slug",
        element: <Room />
      },
      {
        path: "/booking/:slug/:slug",
        element: <Booking/>
      },
      {
        path: "/contact",
        element: <Contact />
      },
      {
        path: "/payment-success/:slug",
        element: <PaymentSuccess/>
      },
    ],
  },
]);

function Router() {
  return <RouterProvider router={router} />;
}

export default Router;
