import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// import './RoomCard.css';

const RoomCard = ({ roomName, availableNumber, price, roomNumbers, roomCapacity, onSelect }) => {
  const location = useLocation();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");
  const roomNameSlug = pathSegments.length > 0 ? pathSegments[pathSegments.length - 1].toLowerCase().replace(/\s+/g, '-') : null;
  const convertedRoomName = roomName.toLowerCase().replace(/\s+/g, '-');

  const [counter, setCounter] = useState(1);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [selectButtonVisible, setSelectButtonVisible] = useState(true);
  const [includeBreakfast, setIncludeBreakfast] = useState(false);

  useEffect(() => {
    setSelectedRooms(
      Array.from({ length: counter }, () => ({
        adults: 1,
        children: 0,
        extraMattress: false,
      }))
    );
  }, [counter]);

  const handleIncrementRoom = () => {
    if (counter < availableNumber) {
      setCounter(prevCounter => prevCounter + 1);
      setSelectedRooms(prevRooms => [
        ...prevRooms,
        { adults: 1, children: 0, extraMattress: false }
      ]);
    } else {
      alert('Not enough available rooms for the selected number of guests');
    }
    setSelectButtonVisible(true);
  };

  const handleDecrementRoom = () => {
    if (counter > 1) {
      setCounter(prevCounter => prevCounter - 1);
      setSelectedRooms(prevRooms => prevRooms.slice(0, -1));
    }
    setSelectButtonVisible(true);
  };
  const handleIncrementAdults = (index) => {
    const updatedRooms = [...selectedRooms];
    const totalRoomCapacity = roomCapacity;
  
    // Only increase the adults count if it doesn't exceed the total room capacity
    if (updatedRooms[index].adults < totalRoomCapacity) {
      updatedRooms[index].adults += 1;
    }
    setSelectedRooms(updatedRooms);
    setSelectButtonVisible(true);
  };
  

  const handleDecrementAdults = (index) => {
    const updatedRooms = [...selectedRooms];
    if (updatedRooms[index].adults > 1) {
      updatedRooms[index].adults -= 1;
    }
    setSelectedRooms(updatedRooms);
    setSelectButtonVisible(true);
  };

  const handleIncrementChildren = (index) => {
    const updatedRooms = [...selectedRooms];
    const totalRoomCapacity = roomCapacity;
  
    // Only increase the children count if it doesn't exceed the room capacity and is less than 1
    const totalGuests = updatedRooms[index].adults + updatedRooms[index].children;
    if (updatedRooms[index].children < 1) {
      updatedRooms[index].children += 1;
    }
    setSelectedRooms(updatedRooms);
    setSelectButtonVisible(true);
  };
  

  const handleDecrementChildren = (index) => {
    const updatedRooms = [...selectedRooms];
    if (updatedRooms[index].children > 0) {
      updatedRooms[index].children -= 1;
    }
    setSelectedRooms(updatedRooms);
    setSelectButtonVisible(true);
  };

  const handleSelectExtras = (index, extra) => {
    const updatedRooms = [...selectedRooms];
    updatedRooms[index][extra] = !updatedRooms[index][extra];
    setSelectedRooms(updatedRooms);
    setSelectButtonVisible(true);
  };

  const handleBreakfastToggle = () => {
    setIncludeBreakfast(!includeBreakfast);
    setSelectButtonVisible(true);
  };

  const calculateTotalPrice = () => {
    const basePrice = counter * price;
    const extraMattressCost = selectedRooms.filter(room => room.extraMattress).length * 500;
    const totalPax = selectedRooms.reduce((total, room) => total + room.adults + room.children, 0);
    const breakfastCost = includeBreakfast ? totalPax * 100 : 0;

    return basePrice + extraMattressCost + breakfastCost;
  };

  const shouldShowCard = roomNameSlug === convertedRoomName || location.pathname.includes("show-all-rooms");

  const handleSelect = () => {
    const totalAdult = selectedRooms.reduce((total, room) => total + room.adults, 0);
    const totalChildren = selectedRooms.reduce((total, room) => total + room.children, 0);
    const totalMattress = selectedRooms.filter(room => room.extraMattress).length;
    const totalPrice = calculateTotalPrice();
    const totalBreakfast = includeBreakfast ? totalAdult + totalChildren : 0;

    onSelect({
      roomName,
      count: counter,
      totalRoom: counter,
      totalAdult,
      totalChildren,
      totalPrice,
      totalBreakfast,
      totalMattress,
      shouldShowCard,
    });
    setSelectButtonVisible(false);
  };

  return (
    <>
      {shouldShowCard && (
        <div className="card my-3 shadow-sm border-0 rounded">
          <div className="card-body p-4">
            <div className="row">
              <div className="col-md-6">
                <h2 className="card-title text-primary">{roomName}</h2>
                <ul className="room-details list-unstyled">
                  <li className="mb-2">
                    <span className="fw-bold">Available:</span> {availableNumber} {availableNumber === 1 ? 'Room' : 'Rooms'}
                  </li>
                  <li className="mb-2">
                    <span className="fw-bold">Price:</span> INR <span className="badge bg-info text-dark">{price}</span> per night
                  </li>
                  <li className="mb-2 text-success">
                    {counter} x {roomName}
                  </li>
                </ul>
              </div>
              <div className="col-md-6 text-end">
                <div className="btn-group" role="group">
                  <button type="button" className="btn btn-outline-secondary" onClick={handleDecrementRoom} disabled={counter <= 1}>
                    - Remove Room
                  </button>
                  <button type="button" className="btn btn-outline-primary" onClick={handleIncrementRoom} disabled={counter >= availableNumber}>
                    + Add Another Room
                  </button>
                </div>
              </div>
            </div>

            {selectedRooms.map((room, index) => (
              <div key={index} className="row mt-4" style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                <div className="col-md-6">
                  <h6 className="text-muted">Room {index + 1}</h6>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <span>Adults</span>
                    <div className="btn-group">
                      <button className="btn btn-sm btn-outline-secondary" onClick={() => handleDecrementAdults(index)}>-</button>
                      <span className="mx-3">{room.adults}</span>
                      <button className="btn btn-sm btn-outline-secondary" onClick={() => handleIncrementAdults(index)}>+</button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <span>Children (0 - 12 years)</span>
                    <div className="btn-group">
                      <button className="btn btn-sm btn-outline-secondary" onClick={() => handleDecrementChildren(index)}>-</button>
                      <span className="mx-3">{room.children}</span>
                      <button className="btn btn-sm btn-outline-secondary" onClick={() => handleIncrementChildren(index)}>+</button>
                    </div>
                  </div>
                  {room.adults + room.children > roomCapacity && (
                    <div className="text-danger mt-2" style={{ fontSize: "14px" }}>
                      <i className="bi bi-exclamation-triangle"></i> Total occupancy exceeds room capacity!
                    </div>
                  )}
                </div>
                <div className="col-md-6 text-end d-flex align-items-center justify-content-end">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`matrix-${index}`}
                      checked={room.extraMattress}
                      onChange={() => handleSelectExtras(index, 'extraMattress')}
                    />
                    <label htmlFor={`matrix-${index}`} className="form-check-label">
                      Extra Mattress (INR 500)
                    </label>
                  </div>
                </div>
              </div>
            ))}
            <div className='row' style={{backgroundColor:'#e1f6f6', padding:'15px', margin:'10px 0'}}>
              <div className='col-md-6' style={{borderRight:'1px solid #7ed9e1'}}>
                <h5>Travelling with children?</h5>
                <p>If you are travelling with children, add number of children and their age to get the best rooms options, prices etc.</p>
              </div>
              <div className='col-md-6'>
                <ul className='room-details list-unstyled'>
                  <li> Kids below 12 Years Complimentary</li>
                  <li> Children above 12 will be considered as adults</li>
                  <li> Extra Mattress Will be provided for guests above 12</li>
                  <li> Breakfast is chargeable for for kids above 5 Years</li>
                </ul>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-6 d-flex align-items-center">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="breakfast"
                    checked={includeBreakfast}
                    onChange={handleBreakfastToggle}
                  />
                  <label htmlFor="breakfast" className="form-check-label">
                    Include Breakfast (INR 100 per person)
                  </label>
                </div>
              </div>
              <div className="col-md-6 text-end">
                <h5>Total Price: INR {calculateTotalPrice()}</h5>
              </div>
            </div>

            {selectButtonVisible && (
              <div className="text-end mt-4">
                <button className="btn btn-primary" onClick={handleSelect}>
                  Select Room
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default RoomCard;
