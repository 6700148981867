import axios from './axios.interceptor';
import api from './api.util';

export const checkAvabUrl = `${api}/availability`; 
export const bookingApi = `${api}/booking`;
export function checkavAilability( obj ) {
    const asd = axios.post(checkAvabUrl, obj, {
      cors: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type"
      }
    });
    return asd;
}

export function createBooking( obj ) {
  const asd = axios.post(bookingApi, obj, {
    cors: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type"
    }
  });
  return asd;
}