import React, {useState , useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createBooking } from '../../../crud/book.crud';
import { Modal, Button } from 'react-bootstrap';
import { IoMdCheckmarkCircle } from "react-icons/io";
const Step4 = ({ userData, onConfirm, onBack }) => {
    const navigate = useNavigate();
    const { bookingFrom, bookingUpto, hotel_slug, clientEmail, clientName, clientPhone, selectedRooms } = userData;
    const [showModal, setShowModal] = useState(false);
    // Helper function to calculate the difference in days between two dates
    const calculateDayCount = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        
        // Adjust for check-in/check-out times
        start.setHours(12, 0, 0); // Check-in at 12:00 PM
        end.setHours(11, 0, 0);   // Check-out at 11:00 AM
        
        const diffTime = Math.abs(end - start);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    };

    // Calculate total number of days
    const totalDays = calculateDayCount(bookingFrom, bookingUpto);
    

    // Calculate total amount based on selected rooms and day count
    const totalAmount = selectedRooms.reduce((sum, room) => sum + (room.price * room.count * totalDays), 0);

    // Extract unique room numbers from selected rooms
    const allRoomNumbers = [...new Set(selectedRooms.flatMap(room => room.roomNumbers))];

    useEffect(() => {
    }, [selectedRooms]);

    useEffect(() => {
    }, [allRoomNumbers]);

    // Handle booking confirmation
    const handleConfirm = async () => {
        const bookingData = {
            amount: selectedRooms[0].totalPrice * totalDays,
            roomNumbers: allRoomNumbers,
            bookingFrom,
            bookingUpto,
            clientName,
            clientEmail,
            clientPhone,
            selectedRooms,
            hotel_slug,
            totalDays
        };

        try {
            const response = await createBooking(bookingData);
            if (response) {
                // navigate(`/`);
                // alert("Booking Successful!");
                setShowModal(true);
            }
        } catch (error) {
            console.error("Error during booking:", error);
            alert("Booking failed. Please try again.");
        }
    };
    const handleCloseModal = () => {
        setShowModal(false);
        navigate(`/`);
    };
    return (
        <div className="container my-5">
            <h3 className="my-4 text-primary text-center">Step 4: Review Details & Payment</h3>
            <div className="review-details bg-white p-4 rounded shadow-lg">
                <div className="mb-4">
                    <h5 className="text-secondary">Personal Information</h5>
                    <div className="d-flex justify-content-between border-bottom pb-2 mb-3">
                        <p className="mb-0"><strong>Name:</strong> {clientName}</p>
                        <p className="mb-0"><strong>Email:</strong> {clientEmail}</p>
                    </div>
                    <div>
                        <p><strong>Phone:</strong> {clientPhone}</p>
                    </div>
                </div>
                <div className="mb-4">
                    <h5 className="text-secondary">Booking Information</h5>
                    <div className="d-flex justify-content-between border-bottom pb-2 mb-3">
                        <p className="mb-0"><strong>Check-in:</strong> {bookingFrom} at 12:00 PM</p>
                        <p className="mb-0"><strong>Check-out:</strong> {bookingUpto} at 11:00 AM</p>
                    </div>
                </div>
                <div className="mb-4">
                    <h5 className="text-secondary">Selected Rooms</h5>
                    {selectedRooms.map((room, index) => (
                        <div key={index} className="room-detail bg-light border rounded p-3 mb-3">
                            <p className="mb-1"><strong>Room Name:</strong> {room.roomName}</p>
                            <p className="mb-1"><strong>Total Rooms:</strong> {room.count}</p>
                            <p className="mb-1"><strong>Total Adults:</strong> {room.totalAdult}</p>
                            <p className="mb-1"><strong>Total Children:</strong> {room.totalChildren}</p>
                            <p className="mb-1"><strong>Price:</strong> INR {room.totalPrice}</p>

                            {/* Conditionally render breakfast details if available */}
                            {room.totalBreakfast > 0 && (
                                <p className="mb-1">
                                    <strong>Total Breakfast:</strong> {room.totalBreakfast}
                                    <span className="text-muted">(INR {room.totalBreakfast * 100})</span>
                                </p>
                            )}

                            {/* Conditionally render mattress details if available */}
                            {room.totalMattress > 0 && (
                                <p className="mb-1">
                                    <strong>Extra Mattress:</strong> {room.totalMattress}
                                    <span className="text-muted">(INR {room.totalMattress * 500})</span>
                                </p>
                            )}
                        </div>
                    ))}

                </div>
                <div className="total-amount mb-4 text-right">
                    <h5>Total Amount : <span className="text-primary">INR {selectedRooms[0].totalPrice * totalDays} / {totalDays > 1 ? `${totalDays} days` : 'per day'} </span></h5>
                </div>
                <button className="btn btn-primary w-100 py-3" onClick={handleConfirm}>
                    Confirm Booking
                </button>
                <div onClick={onBack} className="mt-3 text-primary text-center" style={{ cursor: "pointer" }}>
                    <i className="bx bx-left-arrow-alt"></i> Back
                </div>
            </div>

             {/* Success Modal */}
             <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title className='text-success'><IoMdCheckmarkCircle className='' style={{ fontSize: '40px'}} /> Booking Successful!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Name :</strong> {clientName}</p>
                    <p><strong>Email :</strong> {clientEmail}</p>
                    <p><strong>Phone :</strong> {clientPhone}</p>
                    <p><strong>Check-in :</strong> {bookingFrom} at 12:00 PM</p>
                    <p><strong>Check-out :</strong> {bookingUpto} at 11:00 AM</p>
                    <p><strong>Room Name :</strong> {selectedRooms[0].roomName}</p>
                    <p><strong>Hotel Name :</strong> {hotel_slug}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Go to Homepage
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Step4;
