
import React, { useState, useEffect } from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import imgOne from '../../assets/img/carousel-4.png';
import imgTwo from '../../assets/img/carousel-2.jpg';

// import required modules
import { Navigation } from 'swiper/modules';

const Banner = () => {

    return (
        <>
            <div class="container-fluid p-0 mb-5">
                <div id="header-carousel" class="carousel slide" data-bs-ride="carousel">
                    <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
                        <SwiperSlide>
                            <div className="carousel-item active">
                                <img className="w-100" src={imgOne} alt="img" style={{height:"790px",width:"100%"}} />
                                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                    <div className="p-3" style={{ maxWidth: 700 }}>
                                        <h6 className="section-title text-white text-uppercase mb-3 animated slideInDown">
                                            Luxury Living
                                        </h6>
                                        <h1 className="display-3 text-white mb-4 animated slideInDown" style={{lineHeight:"80px"}}>
                                            <span className="p-0 m-0 home-title">ODYSSEY</span><br/><span className="p-0 m-0 home-title-two"> GROUP OF HOTELS</span>
                                        </h1>
                                        
                                        <a
                                            href="/hotels"
                                            className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                                        >
                                            Our Hotels
                                        </a>
                                        
                                    </div>
                                </div>
                            </div>

                        </SwiperSlide>
                        {/* <SwiperSlide>
                            <div className="carousel-item active">
                                <img className="w-100" src={imgTwo} alt="img" style={{height:"790px",width:"100%"}} />
                                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                    <div className="p-3" style={{ maxWidth: 700 }}>
                                        <h6 className="section-title text-white text-uppercase mb-3 animated slideInDown">
                                            Luxury Living
                                        </h6>
                                        <h1 className="display-3 text-white mb-4 animated slideInDown">
                                            Discover A Brand Luxurious Hotel
                                        </h1>
                                        <a
                                            href="/hotels"
                                            className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                                        >
                                            Our Hotels
                                        </a>
                                        
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide> */}
                    </Swiper>
                </div>
            </div>
        </>
    );
};

export default Banner;
