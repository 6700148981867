import React, { useState } from 'react';
import { checkavAilability } from '../../../crud/book.crud';

const Step1 = ({ onNext, sendRoom, initialData, slugHotel }) => {
    const [availabilityData, setAvailabilityData] = useState({
        bookingFrom: initialData.bookingFrom || '',
        bookingUpto: initialData.bookingUpto || '',
        hotel_slug: initialData.hotel_slug || slugHotel || ''
    });

    const [errors, setErrors] = useState({});
    const [adultCount, setAdultCount] = useState(1);
    const [childCount, setChildCount] = useState(0);

    const handleIncrement = (setCount) => () => setCount(prevCount => prevCount + 1);
    const handleDecrement = (setCount, min) => () => setCount(prevCount => Math.max(prevCount - 1, min));

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAvailabilityData(prev => ({ ...prev, [name]: value }));
    };

    const validate = () => {
        let errors = {};
        if (!availabilityData.bookingFrom) errors.bookingFrom = 'Check-in date is required';
        if (!availabilityData.bookingUpto) errors.bookingUpto = 'Check-out date is required';
        if (!availabilityData.hotel_slug) errors.hotel_slug = 'Hotel selection is required';
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const checkAvailability = async (e) => {
        e.preventDefault();
        if (!validate()) return;

        try {
            const res = await checkavAilability(availabilityData);
            const isAvailable = res.data.available;
            const availableRooms = res.data.availableRooms;
            if (isAvailable) {
                sendRoom(availableRooms);
                const pax = { adultCount, childCount };  // Combine pax data
                onNext({ ...availabilityData, pax });
            } else {
                alert('Room not available. Please try another date.');
            }
        } catch (error) {
            console.error('Error checking availability:', error);
            alert('An error occurred while checking availability. Please try again later.');
        }
    };

    return (
        <div className="container my-5">
            <h3 className="my-4 text-primary text-center">Step 1: Check Availability</h3>
            <div className="availability-form wow fadeInUp" data-wow-delay="0.2s">
                <form onSubmit={checkAvailability}>
                    <div className="row g-3">
                        <div className="col-md-6">
                            <div className="form-floating">
                                <input
                                    type="date"
                                    className={`form-control ${errors.bookingFrom ? 'is-invalid' : ''}`}
                                    id="bookingFrom"
                                    name="bookingFrom"
                                    value={availabilityData.bookingFrom}
                                    onChange={handleChange}
                                    placeholder="Check In"
                                />
                                <label htmlFor="bookingFrom">Check In</label>
                                {errors.bookingFrom && <div className="invalid-feedback">{errors.bookingFrom}</div>}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-floating">
                                <input
                                    type="date"
                                    className={`form-control ${errors.bookingUpto ? 'is-invalid' : ''}`}
                                    id="bookingUpto"
                                    name="bookingUpto"
                                    value={availabilityData.bookingUpto}
                                    onChange={handleChange}
                                    placeholder="Check Out"
                                />
                                <label htmlFor="bookingUpto">Check Out</label>
                                {errors.bookingUpto && <div className="invalid-feedback">{errors.bookingUpto}</div>}
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-floating">
                                <select
                                    className={`form-select ${errors.hotel_slug ? 'is-invalid' : ''}`}
                                    id="hotel_slug"
                                    name="hotel_slug"
                                    value={availabilityData.hotel_slug}
                                    onChange={handleChange}
                                >
                                    <option value="">-- Select Hotel --</option>
                                    <option value="the-odyssey-inn">The Odyssey Inn</option>
                                    <option value="the-greenstar-inn">The Greenstar Inn</option>
                                </select>
                                <label htmlFor="hotel_slug">Select A Hotel</label>
                                {errors.hotel_slug && <div className="invalid-feedback">{errors.hotel_slug}</div>}
                            </div>
                        </div>
                        {/* <div className="col-md-6">
                            <label className='mb-3'>Adult</label>
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-danger" onClick={handleDecrement(setAdultCount, 1)}>-</button>
                                <button type="button" className="btn btn-light" disabled>{adultCount}</button>
                                <button type="button" className="btn btn-success" onClick={handleIncrement(setAdultCount)}>+</button>
                            </div>
                        </div> */}
                        {/* <div className="col-md-6">
                            <label className='mb-3'>Child (0-12 yr)</label>
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-danger" onClick={handleDecrement(setChildCount, 0)}>-</button>
                                <button type="button" className="btn btn-light" disabled>{childCount}</button>
                                <button type="button" className="btn btn-success" onClick={handleIncrement(setChildCount)}>+</button>
                            </div>
                        </div> */}
                        <div className="col-12">
                            <button className="btn btn-primary w-100 py-3" type="submit">
                                Check Availability
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Step1;
