
import headerImg from "../../assets/img/carousel-1.jpg";

export default function Contacts(){
    return(
        <>
          {/* header start */}
          <div
                className="container-fluid page-header mb-5 p-0"
                style={{ backgroundImage: `url(${headerImg})` }}
            >
                <div className="container-fluid page-header-inner py-5">
                    <div className="container text-center pb-5">
                        <h1 className="display-3 text-white mb-3 animated slideInDown">
                            Contact
                        </h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center text-uppercase">
                                <li className="breadcrumb-item">
                                    <a href="#">Home</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="#">Pages</a>
                                </li>
                                <li className="breadcrumb-item text-white active" aria-current="page">
                                Contact
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            {/* header end */}
            <div className="container-xxl py-5">
  <div className="container">
    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
      <h6 className="section-title text-center text-primary text-uppercase">
        Contact Us
      </h6>
      <h1 className="mb-5">
        <span className="text-primary text-uppercase">Contact</span> For Any
        Query
      </h1>
    </div>
    <div className="row g-4">
      <div className="col-12">
        <div className="row gy-4">
          <div className="col-md-4">
            <h6 className="section-title text-start text-primary text-uppercase">
              Booking Puri
            </h6>
            <p>
              <i className="fa fa-envelope-open text-primary me-2" />
              reservation.puri@odysseyhotels.in
            </p>
          </div>
          <div className="col-md-4">
            <h6 className="section-title text-start text-primary text-uppercase">
            Booking Bhubneshwar
            </h6>
            <p>
              <i className="fa fa-envelope-open text-primary me-2" />
              reservation.bbsr@odysseyhotels.in
            </p>
          </div>
          <div className="col-md-4">
            <h6 className="section-title text-start text-primary text-uppercase">
              General
            </h6>
            <p>
              <i className="fa fa-envelope-open text-primary me-2" />
              info@odysseyhotels.in
            </p>
          </div>
          
        </div>
      </div>
      <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
        <iframe
          className="position-relative rounded w-100 h-100"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4594.826068586286!2d85.82165300572858!3d19.806978765551253!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a19c4018f87cb17%3A0x5a4d6b974d176e9c!2sTHE%20ODYSSEY%20INN!5e0!3m2!1sen!2sin!4v1727672383941!5m2!1sen!2sin"
          frameBorder={0}
          style={{ minHeight: 350, border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex={0}
        />
      </div>
      <div className="col-md-6">
        <div className="wow fadeInUp" data-wow-delay="0.2s">
          <form>
            <div className="row g-3">
              <div className="col-md-6">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                  />
                  <label htmlFor="name">Your Name</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Your Email"
                  />
                  <label htmlFor="email">Your Email</label>
                </div>
              </div>
              <div className="col-12">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="subject"
                    placeholder="Subject"
                  />
                  <label htmlFor="subject">Subject</label>
                </div>
              </div>
              <div className="col-12">
                <div className="form-floating">
                  <textarea
                    className="form-control"
                    placeholder="Leave a message here"
                    id="message"
                    style={{ height: 150 }}
                    defaultValue={""}
                  />
                  <label htmlFor="message">Message</label>
                </div>
              </div>
              <div className="col-12">
                <button className="btn btn-primary w-100 py-3" type="submit">
                  Send Message
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

        </>
    )
}