import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import ProgressBar from '../../ProgressBar';

import aboutImgOne from "../../../assets/img/about-1.jpg";
import aboutImgTwo from "../../../assets/img/about-2.jpg";
import aboutImgThree from "../../../assets/img/about-3.jpg";
import aboutImgFour from "../../../assets/img/about-4.jpg";
import headerImg from "../../../assets/img/carousel-1.jpg";
export default function Booking(props) {
    const location = useLocation();
    const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");
  const slugHotel = pathSegments.length > 0 ? pathSegments[pathSegments.length - 2] : null;

  const roomName = pathSegments.length > 0 ? pathSegments[pathSegments.length - 1] : null;

    
    const initialData = location.state || {};

    const [step, setStep] = useState(initialData.availableRooms ? 2 : 1);
    const [userData, setUserData] = useState(initialData.searchData || {});
    const [avabRooms, setAvabRooms] = useState(initialData.availableRooms || null);
    
    const sendRoom = (data) =>{
        setAvabRooms(data)
    }
    const nextStep = (data) => {
        setUserData({ ...userData, ...data }); // Merge previous user data with new data
        setStep(prevStep => (prevStep < 4 ? prevStep + 1 : prevStep));
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const confirmAndPay = () => {
        // Implement payment logic here
        alert('Payment confirmed. Thank you!');
    };

    const getProgressWidth = (step) => {
        return ((step - 1) / 3) * 100;
    };

    const getStepLabel = (currentStep, targetStep) => {
        return currentStep > targetStep ? '✔️' : `Step ${targetStep}`;
    };

    return (
        <>
            {/* header start */}
            <div
                className="container-fluid page-header mb-5 p-0"
                style={{ backgroundImage: `url(${headerImg})` }}
            >
                <div className="container-fluid page-header-inner py-5">
                    <div className="container text-center pb-5">
                        <h1 className="display-3 text-white mb-3 animated slideInDown">
                            Booking
                        </h1>
                        
                    </div>
                </div>
            </div>
            {/* header end */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title text-center text-primary text-uppercase">
                            Room Booking
                        </h6>
                        <h1 className="mb-5">
                            Book A <span className="text-primary text-uppercase">{roomName}</span>
                        </h1>
                    </div>
                    <div className="row g-5">
                        {/* <div className="col-lg-4">
                            <div className="row g-3">
                                <div className="col-6 text-end">
                                    <img
                                        className="img-fluid rounded w-75 wow zoomIn"
                                        data-wow-delay="0.1s"
                                        
                                        src={aboutImgOne}
                                        style={{ marginTop: "25%" }}
                                    />
                                </div>
                                <div className="col-6 text-start">
                                    <img
                                        className="img-fluid rounded w-100 wow zoomIn"
                                        
                                        data-wow-delay="0.3s"
                                        src={aboutImgTwo}
                                    />
                                </div>
                                <div className="col-6 text-end">
                                    <img
                                        className="img-fluid rounded w-50 wow zoomIn"
                                        
                                        data-wow-delay="0.5s"
                                        src={aboutImgThree}
                                    />
                                </div>
                                <div className="col-6 text-start">
                                    <img
                                        className="img-fluid rounded w-75 wow zoomIn"
                                        
                                        data-wow-delay="0.7s"
                                        src={aboutImgFour}
                                    />
                                </div>
                            </div>
                        </div> */}
                        <div className="">
                            <ProgressBar step={step} />
                            <div>
                                {step === 1 && <Step1 onNext={nextStep} sendRoom={sendRoom} initialData={userData} slugHotel={slugHotel} />}
                                {step === 2 && <Step2 onNext={nextStep} onBack={prevStep} allRoomsAvab={avabRooms} initialData={userData} />}
                                {step === 3 && <Step3 onNext={nextStep} onBack={prevStep} initialData={userData} />}
                                {step === 4 && <Step4 userData={userData} onConfirm={confirmAndPay} onBack={prevStep} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    ); }
