
import konark from "../../assets/img/konark.png";
import Asiatic_lion_in_nandankanan from "../../assets/img/nandan.png";
import Jagannath_Temple from "../../assets/img/jagannath.png";
import chilika_lake from "../../assets/img/chilika lake.jpg";
import Dhauli_Giri_Shanti_Stupa from "../../assets/img/Dhauli-Giri-Shanti-Stupa.jpg";
import Udayagiri_Caves from "../../assets/img/Udayagiri_Caves.jpg";
import puriSeaBeach from "../../assets/img/puriSeaBeach.jpeg";
import lingarajTemple from "../../assets/img/lingarajTemple.jpeg";
import khandagri from "../../assets/img/khandagri.jpeg";
export default function Services() {
    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    {/* <h6 className="section-title text-center text-primary text-uppercase">
                        Our Services
                    </h6> */}
                    <h1 className="mb-5">
                    Explore Near By {" "}
                        <span className="text-primary ">Attractions</span>
                    </h1>
                </div>
                <div className="row g-4">
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="service-item rounded" href="">
                            <div className="  border rounded p-1">
                                <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                    {/* <i className="fa fa-hotel fa-2x text-primary" /> */}
                                    <img src={konark} className="img-fluid attraction-img-height" />
                                </div>
                            </div>
                            <h5 className="mb-3 mt-3 heading-five">konark Sun Temple</h5>
                            
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                        <div className="service-item rounded" href="">
                            <div className="border rounded p-1">
                                <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                <img src={Asiatic_lion_in_nandankanan} className="img-fluid attraction-img-height" />
                                </div>
                            </div>
                          <h5 className="mb-3 mt-3 heading-five"  >Asiatic Lion In Nandankanan</h5>

                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="service-item rounded" href="">
                            <div className="border rounded p-1">
                                <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                <img src={Jagannath_Temple} className="img-fluid attraction-img-height" />
                                </div>
                            </div>
                            <h5 className="mb-3 mt-3 heading-five"  >Jagannath Temple</h5>
                            
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
                        <div className="service-item rounded" href="">
                            <div className="border rounded p-1">
                                <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                <img src={chilika_lake} className="img-fluid attraction-img-height" />
                                </div>
                            </div>
                            <h5 className="mb-3 mt-3 heading-five"  >Chilika Lake</h5>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="service-item rounded" href="">
                            <div className="border rounded p-1">
                                <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                <img src={Dhauli_Giri_Shanti_Stupa} className="img-fluid attraction-img-height" />

                                </div>
                            </div>
                            <h5 className="mb-3 mt-3 heading-five"  >Dhauli Giri Shanti Stupa</h5>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                        <div className="service-item rounded" href="">
                            <div className="border rounded p-1">
                                <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                <img src={Udayagiri_Caves} className="img-fluid attraction-img-height" />
                                </div>
                            </div>
                            <h5 className="mb-3 mt-3 heading-five"  >Udayagiri Caves</h5>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                        <div className="service-item rounded" href="">
                            <div className="border rounded p-1">
                                <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                <img src={puriSeaBeach} className="img-fluid attraction-img-height" />
                                </div>
                            </div>
                            <h5 className="mb-3 mt-3 heading-five"  >Puri Sea Beach</h5>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                        <div className="service-item rounded" href="">
                            <div className="border rounded p-1">
                                <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                <img src={lingarajTemple} className="img-fluid attraction-img-height" />
                                </div>
                            </div>
                            <h5 className="mb-3 mt-3 heading-five" >Lingaraj Temple</h5>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                        <div className="service-item rounded" href="">
                            <div className="border rounded p-1">
                                <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                <img src={khandagri} className="img-fluid attraction-img-height" />
                                </div>
                            </div>
                            <h5 className="mb-3 mt-3 heading-five"  >Khandagiri</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}